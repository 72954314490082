import Swiper, { Pagination } from 'swiper';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';

const quickStartSectionSwiper = new Swiper('.quick-start-swiper', {
    // configure Swiper to use modules
    modules: [Pagination],
    init: true,
    slidesPerView: 1,
    spaceBetween: 30,

    // Optional parameters
    direction: 'horizontal',
    loop: false,

    // If we need pagination
    pagination: {
        el: '.quick-start-swiper-pagination',
        clickable: true,
    },

    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 16,
        },
    },
});

const nextSoundSectionSwiper = new Swiper('.next-sound-swiper', {
    // configure Swiper to use modules
    modules: [Pagination],
    init: true,
    slidesPerView: 1,
    spaceBetween: 30,

    // Optional parameters
    direction: 'horizontal',
    loop: false,

    // If we need pagination
    pagination: {
        el: '.next-sound-swiper-pagination',
        clickable: true,
    },

    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        992: {
            slidesPerView: 5,
            spaceBetween: 32,
        },
    },
});

const faqSectionSwiper = new Swiper('.faq-swiper', {
    // configure Swiper to use modules
    modules: [Pagination],
    init: true,
    slidesPerView: 1,
    spaceBetween: 30,

    // Optional parameters
    direction: 'horizontal',
    loop: false,

    // If we need pagination
    pagination: {
        el: '.faq-swiper-pagination',
        clickable: true,
    },

    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 32,
        },
    },
});

document.addEventListener('DOMContentLoaded', () => {
    // Scroll alla sezione di download degli store:
    document.querySelector('#main-hero-cta').addEventListener('click', (event) => {
        event.preventDefault();
        document.querySelector('.get-it-section').scrollIntoView({ behavior: 'smooth' });
    });
});
